
@font-face {
  font-family: 'Font';
  src: url('./font.ttf') format('truetype');
}

@font-face {
  font-family: 'Font2';
  src: url('./font2.ttf') format('truetype');
}


:root {
  --red: #A32036;
  --black: #000;
  --white: #FFF;
}

.app {
  background-color: var(--white);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Font', sans-serif;
}

.app-header {
  justify-content: space-between;
  align-items: top;
  display: flex;
  padding: 0 20px;  
  width: 97%;
}

.logo {
  height: 50px;  /* Adjust this value for your needs */
  width: auto;
}

.header-button{
  background-color: var(--red);
  color: var(--white);
  font-size: 17px;
  font-weight: 600;
  padding: 9px 35px;
  border: none;
  cursor: pointer;
  text-align: center;
  display: inline-block;
}

.content-button {
  background-color: var(--red);
  color: var(--white);
  margin-top: 20px;
  font-family: 'Font2', sans-serif;
  padding: 10px 50px;
  border: none;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  transition-duration: 0.4s;
  border-radius: 5px;
}


.content-button:hover {
  color: #ffffff;
  font-family: 'Font2', sans-serif;
}

.title{
  color: var(--red);
  text-align: left;
  font-size: 150px;
  margin-top: 0px;
  font-weight: 600;
  margin-left: 0px;
  
}
.subtitle {
  color: var(--black);
  text-align: left;
  font-size: 50px;
  margin-top: -100px;
  margin-bottom: 80px;
  font-weight: 100;
}

h3 {
  color: var(--black);
  text-align: left;
  font-size: 20px;
  margin-top: 50px;
  margin-bottom: 80px;
  font-weight: 600;
  font-family: Font2;
}

.main-content {
  display: flex;
  justify-content: space-between;
  align-items: top;
  width: 90%; /* or any other value that suits your layout */
  margin: 0 auto;
}

.main-content2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: top;
  width: 90%; 
  margin: 0 auto;
}

.section-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.section-container:nth-child(even) {
  flex-direction: row-reverse;
}

.text-container {
  text-align: left;
  width: 800px;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 150px;
}

.image-container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 80px;
}

.right-side-image {
  width: 150px;
  margin-bottom: 20px;
}

.slider-container {
  position: relative;
  display: flex; /* Center text horizontally and vertically */
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  flex-direction: column; /* Added this line */
}

.mint-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  transition: .2s;
  margin-top: 10px;
}

.mint-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #A32036;
  cursor: pointer;
}

.mint-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #A32036;
  cursor: pointer;
}

.slider-value {
  color: #A32036;
  font-family: 'Font2', sans-serif;
  font-size: 30px;
  text-align: center;
}

.form-container {
  display: flex;
  font-family: 'Font2', sans-serif;
  flex-direction: column;
  align-items: center; /* align items to center */
  padding: 20px;
  background-color: #A32036;
  border-radius: 10px;
  color: white;
  width: 80%;
  margin: 0 0 10px auto; /* set the form container to the right */
}

.form-container input[type="text"],
.form-container input[type="email"],
.form-container input[type="checkbox"],
.form-container input[type="tel"],
.form-container select {
  display: flex;
  flex-direction: column;
  width: 80%; /* adjust width as needed */
  padding: 10px;
  margin: 10px 10px 20px;
  border: 2px solid white;
  background-color: transparent;
  color: white;
}

.form-container label {
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
}

.form-container button[type="submit"] {
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: white;
  color: #A32036;
  cursor: pointer;
  border-radius: 5px;
}

.form-container button[type="submit"]:hover {
  background-color: #f8f8f8;
}

.dropdown-button {
  padding: 10px 15px;
  background-color: #A32036;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  position: relative;
  font-family: 'Font2', sans-serif;
}

.dropdown-menu {
  font-family: 'Font2', sans-serif;
  position: absolute; /* Change this from 'relative' to 'absolute' */
  top: 50%; /* This positions the top of the menu at the bottom of the button */
  left: -20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 50px;
  z-index: 1;
  display: flex;
  margin-top: 5px;
  list-style-type: none; 
}

.dropdown-menu.open {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dropdown-menu li {

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f5f5f5;
}


/* Put your existing CSS here */

@media screen and (max-width: 600px) {
  /* Adjust styles for screens that are 600px wide or less */

  .app-header {
    flex-direction: column;
    align-items: center;
  }

  .logo {
    height: 40px;
  }

  .header-button {
    font-size: 14px;
    padding: 8px 28px;
  }

  .content-button {
    font-size: 14px;
    padding: 8px 28px;
  }

  .title {
    font-size: 80px;
  }

  .subtitle {
    font-size: 24px;
    margin-top: -50px;
    margin-bottom: 40px;
  }

  h3 {
    font-size: 16px;
    margin-top: 25px;
    margin-bottom: 40px;
  }

  .main-content {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }


  .main-content2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
    width: 90%; 
    margin: 0 auto;
  }
  
  .my-section {
    width: calc(100% - 100px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .row {
    display: flex;
    width: 100%;
    margin-bottom: 50px;
  }
  
  .row:last-child {
    margin-bottom: 0;
  }
  
  .col {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    max-width: 100%;
    height: auto;
  }
  
  .section-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
    margin-bottom: 50px;
  }
  
 
  .image-container, .image-container2 {
    align-self: flex-start;  
  }
  .text-container {
    align-self: flex-start; 
  }
  

  .right-side-image {
    width: 100px;
  }

  .slider-value {
    font-size: 24px;
  }

  .form-container {
    width: 80%;
  }

}



